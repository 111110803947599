import React from 'react';
import Text from 'common/components/Text';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import Heading from 'common/components/Heading';
import Image from 'common/components/Image';
import Container from 'common/components/UI/Container';
// import Button from 'common/components/Button';
import FeatureBlock from 'common/components/FeatureBlock';
import SectionWrapper, { ContentWrapper } from './payment.style';
import Phone from 'common/assets/image/AGO/AB-Imagenes/a-phone-flags.png';
// import Phone from 'common/assets/image/AGO/loading_gif.gif';
import Zelle from 'common/assets/image/AGO/AB-Imagenes/icon_zelle.png';
import Venmo from 'common/assets/image/AGO/AB-Imagenes/icon_venmo.png';
import Uphold from 'common/assets/image/AGO/AB-Imagenes/icon_uphold.png';
import Transferwise from 'common/assets/image/AGO/AB-Imagenes/icon_transferwise.png';
// import BtnIcon1 from 'common/assets/image/cryptoModern/apple.png';
// import BtnIcon2 from 'common/assets/image/cryptoModern/playstore.png';

const PaymentPortal = () => {
  const Data = useStaticQuery(graphql`
    query {
      appModernJson {
        payment {
          id
          title
          icon {
            publicURL
          }
        }
      }
    }
  `);
  console.log(Data, 'cre');
  return (
    <SectionWrapper id="wallet">
      <Container>
        <ContentWrapper>
          <div className="image">
            <Image src={Phone} alt="Wallet" />
          </div>
          <div className="content">
            <Heading content="Haz y recibe pagos desde cualquier plataforma" />
            <Text content="Consolidados en el bloque de cadena de Etherium (ERC20), disponemos un mundo de posibilidades para ti." />
            <div className="walletfeatures">
              <Fade up>
                {Data.appModernJson.payment.map((feature, index) => (
                  <FeatureBlock
                    key={`feature_point-${index}`}
                    icon={
                      <img
                        src={feature.icon ? feature.icon.publicURL : ''}
                        alt="icon "
                      />
                    }
                    iconPosition="left"
                    title={<Text content={feature.title} />}
                  />
                ))}
              </Fade>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <img
                src={Zelle}
                alt="Zelle"
                style={{ width: '20%', height: '20%' }}
              />
              <img
                src={Venmo}
                alt="Venmo"
                style={{ width: '20%', height: '20%' }}
              />
              <img
                src={Uphold}
                alt="Uphold"
                style={{ width: '20%', height: '20%' }}
              />
              <img
                src={Transferwise}
                alt="Transferwise"
                style={{ width: '20%', height: '20%' }}
              />
            </div>
          </div>
        </ContentWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default PaymentPortal;
