import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Button from 'common/components/Button';
import Card from 'common/components/Card';
import Image from 'common/components/Image';
import FeatureBlock from 'common/components/FeatureBlock';
import Container from 'common/components/UI/Container';
import VisitorSectionWrapper, { SectionObject } from './moreSection.style';

import ImageOne from 'common/assets/image/AGO/AB-Imagenes/a-coins-shattered.png';
import ImageBg from 'common/assets/image/saas/visitor_bg.png';
// import testImg from 'common/assets/image/AGO/loading_gif.gif';

const VisitorSection = ({
  title,
  description,
  textArea,
  imageWrapper,
  btnStyle,
}) => {
  return (
    <VisitorSectionWrapper id="visitorSection">
      <Container>
        <Box {...textArea}>
          <FeatureBlock
            title={<Heading content="Conoce las posibilidades" {...title} />}
            description={
              <Text
                content="Obtén un ROI mensual cuando crees en nuestro en proyecto. Ayudamos a las comunidades a través de servicios convenientes, económicos en dinero y tiempo, fáciles de acceder y disponibles constantemente."
                {...description}
              />
            }
            button={
              <a href="#1">
                <Button title="CONOCE MÁS" {...btnStyle} />
              </a>
            }
          />
        </Box>
      </Container>
      <SectionObject>
        <Card className="objectWrapper" {...imageWrapper}>
          <Zoom>
            <Image src={ImageBg} alt="BgImage" />
          </Zoom>
          <Card className="dashboardWrapper" {...imageWrapper}>
            <Fade left>
              <Image src={ImageOne} alt="VisitorDashboard1" />
            </Fade>
            {/* <Fade>
              <Image style={{marginTop: 150, marginLeft: 150}} src={testImg} alt="VisitorDashboard1" />
            </Fade> */}
          </Card>
        </Card>
      </SectionObject>
    </VisitorSectionWrapper>
  );
};

VisitorSection.propTypes = {
  title: PropTypes.object,
  description: PropTypes.object,
  btnStyle: PropTypes.object,
};

VisitorSection.defaultProps = {
  textArea: {
    width: ['100%', '100%', '45%'],
    ml: [0, 0, '58%'],
  },
  imageWrapper: {
    boxShadow: 'none',
  },
  title: {
    fontSize: ['20px', '26px', '26px', '36px', '48px'],
    fontWeight: '400',
    color: '#0f2137',
    letterSpacing: '-0.010em',
    mb: '20px',
    maxWidth: ['100%', '100%', '100%', '440px', '440px'],
    lineHeight: '1.5',
  },
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: '33px',
    maxWidth: ['100%', '100%', '100%', '440px', '440px'],
  },
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    color: '#fff',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
  },
};

export default VisitorSection;
