import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Image from 'common/components/Image';
import Logo from 'common/components/UIElements/Logo';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import ServiceArea, {
  WidgetArea,
  MenuArea,
  Menu,
  MenuItem,
  CopyrightText,
  HeadBlock,
} from './service.style';
import testImg from 'common/assets/image/AGO/loading_gif.gif';

const Service = () => {
  const data = useStaticQuery(graphql`
    query {
      appModernJson {
        service {
          widgets {
            id
            icon {
              publicURL
            }
            title
            description
          }
        }
      }
    }
  `);
  const { widgets } = data.appModernJson.service;
  const date = new Date();
  const year = date.getFullYear();

  return (
    <ServiceArea>
      <Container>
        <HeadBlock>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Heading
              as="h2"
              content="Envía remesas a tus allegados, desde cualquier parte del mundo"
            />
            <Text content="SENCILLO. RÁPIDO. SEGURO." />
          </div>
        </HeadBlock>
        <WidgetArea>
          {widgets.map((item) => (
            <Box className="col" key={`footer-widget--key${item.id}`}>
              <Image src={item.icon.publicURL} alt={item.title} />
              {/* <Image src={testImg} alt={item.title} /> */}
              <Heading as="h3" content={item.title} />
              <Text content={item.description} />
            </Box>
          ))}
        </WidgetArea>
      </Container>
    </ServiceArea>
  );
};

export default Service;
