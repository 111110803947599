import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Modal } from '@redq/reuse-modal';
import { theme } from 'common/theme/appModern';
import { ResetCSS } from 'common/assets/css/style';
import Sticky from 'react-stickynode';
import Navbar from 'containers/AppModern/Navbar';
import Banner from 'containers/AppModern/Banner';
import ServiceSection from 'containers/AppModern/Service';
import AppSlider from 'containers/AppModern/AppSlider';
import Payment from 'containers/AppModern/Payment';
import BillSection from 'containers/AppModern/BillSection';
import CoinsSection from 'containers/AppModern/CoinsSection';
import MoreSection from 'containers/AppModern/MoreSection';
import ContactSections from 'containers/AppModern/Contact';
import BetaSection from 'containers/AppModern/BetaSection';
import Footer from 'containers/AppModern/Footer';
import GlobalStyle, {
  AppWrapper,
  ContentWrapper,
} from 'containers/AppModern/appModern.style';
import '@redq/reuse-modal/es/index.css';

import SEO from 'components/seo';

export default function () {
  return (
    <ThemeProvider theme={theme}>
      <>
        <SEO title="AGO BANK" />
        <Modal />
        <ResetCSS />
        <GlobalStyle />

        <AppWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-active">
            <Navbar />
          </Sticky>
          <ContentWrapper>
            <Banner />
            <AppSlider />
            <Payment />
            <ServiceSection />
            <CoinsSection />
            <BillSection />
            <MoreSection />
            <BetaSection />
            <ContactSections />
          </ContentWrapper>
          <Footer />
        </AppWrapper>
      </>
    </ThemeProvider>
  );
}
