import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import BgImage from 'common/assets/image/saasClassic/map.png';

const ServiceArea = styled.footer`
  padding: 120px 0;
  background-color: #f6fbf9;
  background-image: url(${BgImage}),
    linear-gradient(-139deg, rgb(31, 79, 132) 0%, rgba(31, 154, 210) 100%);
  background-repeat: no-repeat;
  background-position: 25% center;

  @media (max-width: 990px) {
    padding: 100px 0;
  }
  @media (max-width: 767px) {
    padding: 80px 0;
  }
  @media (max-width: 575px) {
    padding: 60px 0;
  }
`;

export const HeadBlock = styled.div`
  h2 {
    color: ${themeGet('colors.white', 'fff')};
    font-size: 48px;
    line-height: 1.2;
    font-weight: 300;
    letter-spacing: -0.025em;
    margin-bottom: 27px;
    text-align: center;
    @media only screen and (max-width: 1440px) {
      margin-bottom: 15px;
      font-size: 38px;
    }
    @media only screen and (max-width: 768px) {
      font-size: 40px;
      max-width: 100%;
    }
    @media only screen and (max-width: 480px) {
      font-size: 30px;
    }
  }
  p {
    font-size: 16px;
    line-height: 28px;
    color: #fff;
    max-width: 400px;
    @media only screen and (max-width: 768px) {
      max-width: 100%;
      text-align: left;
    }
  }
`;

export const WidgetArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 1008px;
  width: 100%;
  margin: 0 auto;

  .col {
    width: calc(100% / 3);
    text-align: center;
    padding: 0 21px;
    margin-bottom: 5px;
    @media only screen and (max-width: 991px) {
      padding: 0 15px;
    }
    @media only screen and (max-width: 667px) {
      width: calc(100% / 2);
      padding: 0 20px;
    }
    @media only screen and (max-width: 480px) {
      width: 100%;
    }

    img {
      display: inline-block;
      margin-bottom: 27px;
      @media only screen and (max-width: 1366px) {
        height: 35px;
      }
      @media only screen and (max-width: 1366px) {
        margin-bottom: 20px;
      }
    }

    h3 {
      color: ${themeGet('colors.white', '#ffffff')};
      font-size: 18px;
      line-height: 24px;
      font-weight: 500;
      @media only screen and (max-width: 1366px) {
        font-size: 16px;
        line-height: 20px;
      }
      @media only screen and (max-width: 480px) {
        font-size: 18px;
      }
    }

    p {
      color: rgba(255, 255, 255, 0.4);
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 0;
      @media only screen and (max-width: 480px) {
        font-size: 15px;
        line-height: 26px;
      }
    }
  }
`;

export const MenuArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  @media only screen and (max-width: 1366px) {
    padding-top: 50px;
  }
  > a {
    margin-right: 0;
  }
`;

export const Menu = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 37px;
  margin-bottom: 15px;
  @media only screen and (max-width: 667px) {
    margin-top: 30px;
  }
`;

export const MenuItem = styled.li`
  margin: 0 18px;
  @media only screen and (max-width: 667px) {
    margin: 5px 15px;
  }
  a {
    color: ${themeGet('colors.white', '#ffffff')};
    transition: all 0.3s ease;
    &:hover {
      color: rgba(255, 255, 255, 0.7);
    }
  }
`;

export const CopyrightText = styled.p`
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 0;
  font-size: 14px;
`;

export default ServiceArea;
