import React from 'react';
import Text from 'common/components/Text';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import Heading from 'common/components/Heading';
import Image from 'common/components/Image';
import Container from 'common/components/UI/Container';
import Button from 'common/components/Button';
import FeatureBlock from 'common/components/FeatureBlock';
import SectionWrapper, { ContentWrapper } from './billSection.style';
import BillImg from 'common/assets/image/AGO/AB-Imagenes/a-phone-receipt.png';
import BtnIcon1 from 'common/assets/image/cryptoModern/apple.png';
import BtnIcon2 from 'common/assets/image/cryptoModern/playstore.png';
import testImg from 'common/assets/image/AGO/loading_gif.gif';

const WalletPortal = () => {
  const Data = useStaticQuery(graphql`
    query {
      appModernJson {
        bills {
          id
          title
          icon {
            publicURL
          }
        }
      }
    }
  `);
  console.log(Data, 'cre');
  return (
    <SectionWrapper id="wallet">
      <Container>
        <ContentWrapper>
          <div className="content">
            <Heading content="Paga cualquiera de tus facturas con Ago Tokens" />
            <Text content="Automatiza el pago de tus servicios con solo pedirlo a tu operador o programarlo en tu billetera." />
            <div className="walletfeatures">
              <Fade up>
                {Data.appModernJson.bills.map((feature, index) => (
                  <FeatureBlock
                    key={`feature_point-${index}`}
                    icon={
                      <img
                        src={feature.icon ? feature.icon.publicURL : ''}
                        alt="icon "
                      />
                    }
                    iconPosition="left"
                    title={<Text content={feature.title} />}
                  />
                ))}
              </Fade>
            </div>
          </div>
          <div className="image">
            <Image src={BillImg} alt="Wallet" />
            {/* <Image src={testImg} alt="Wallet" /> */}
          </div>
        </ContentWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default WalletPortal;
